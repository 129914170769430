import classNames from "classnames"
import Image from "next/legacy/image"
import Link from "next/link"
import { createElement, CSSProperties, ReactNode } from "react"

import { WPMedia } from "@/models"

import styles from "./Card.module.scss"

export interface CardProps {
  image?: WPMedia
  blur?: boolean
  shadow?: boolean
  href?: string
  imageHeight?: number
  imageLink?: string
  className?: string
  children?: ReactNode
  style?: CSSProperties
}

export const Card = ({
  image,
  blur,
  shadow,
  href,
  imageHeight,
  imageLink,
  className,
  children,
  style,
}: CardProps) => {
  const needsNextLink = typeof href === "string" && href.startsWith("/")
  const cardImage = (
    <div
      className={classNames(styles.imageWrapper, { [styles.blur]: blur })}
      style={{ height: imageHeight ?? 242 }}
    >
      {image && (
        <Image src={image.mediaItemUrl} alt={image.altText} layout="fill" objectFit="cover" />
      )}
    </div>
  )

  const cardChildren = (
    <>
      {imageLink ? (
        <Link href={imageLink} className={styles.imageLink}>
          {cardImage}
        </Link>
      ) : (
        cardImage
      )}
      <div className={styles.wrapper}>{children}</div>
    </>
  )
  const container = createElement(
    href ? "a" : "div",
    {
      className: classNames(styles.container, className, {
        [styles.link]: !!href,
        [styles.shadow]: shadow,
      }),
      style,
    },
    cardChildren
  )

  return needsNextLink ? (
    <Link href={href} legacyBehavior>
      {container}
    </Link>
  ) : (
    container
  )
}
