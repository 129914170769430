import { MutableRefObject, useCallback, useState } from "react"
import Slider from "react-slick"

export function useSliderController(sliderRef: MutableRefObject<Slider | undefined>) {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0)

  // create memoized callbacks for the slider controllers
  const slideChangeCallback = useCallback(
    (_: number, next: number) => setActiveSlideIndex(next),
    [setActiveSlideIndex]
  )
  const previousCallback = useCallback(
    () => sliderRef.current?.slickGoTo(activeSlideIndex - 1),
    [sliderRef, activeSlideIndex]
  )
  const nextCallback = useCallback(
    () => sliderRef.current?.slickGoTo(activeSlideIndex + 1),
    [sliderRef, activeSlideIndex]
  )
  const gotoCallback = useCallback(
    (i: number) => {
      if (sliderRef.current) {
        sliderRef.current?.slickGoTo(i)
      } else {
        setActiveSlideIndex(i)
      }
    },
    [sliderRef]
  )

  return {
    activeSlideIndex,
    slideChangeCallback,
    previousCallback,
    nextCallback,
    gotoCallback,
  }
}
