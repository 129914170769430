import classNames from "classnames"

import { AppButton } from "@components/app-button"

import styles from "./SliderController.module.scss"

export interface SliderControllerProps {
  count: number
  activeIndex: number
  className?: string
  onPrevious?(): void
  onGoTo?(_i: number): void
  onNext?(): void
}

export const SliderController = ({
  count,
  activeIndex,
  className,
  onPrevious,
  onGoTo,
  onNext,
}: SliderControllerProps) => {
  // create pagination dots
  const dots = Array(count)
    .fill(0)
    .map((_, i) => {
      const classes = classNames(styles.dot, { [styles.active]: activeIndex === i })
      return <div key={i} className={classes} onClick={() => onGoTo?.(i)}></div>
    })

  // combine classes of container
  const classes = classNames(styles.container, className)

  return (
    <div className={classes}>
      <div className={styles.dots}>{dots}</div>
      <div className={styles.arrows}>
        <AppButton nav="prev" onClick={onPrevious} disabled={activeIndex === 0} />
        <AppButton nav="next" onClick={onNext} disabled={activeIndex + 1 === count} />
      </div>
    </div>
  )
}
