import classNames from "classnames"
import Image from "next/legacy/image"
import Link from "next/link"
import { createElement, CSSProperties, useCallback, useRef, useState } from "react"

import { WPMedia } from "@/models"
import { CirclePlusIcon } from "@components/app-icons"
import { Wysiwyg } from "@components/wysiwyg"

import styles from "./IconText.module.scss"

export interface IconTextProps {
  icon: WPMedia
  text: string
  description?: string
  banner?: string
  readMore?: string
  href?: string
  bg?: "default" | "white"
  className?: string
  style?: CSSProperties
}

export const IconText = ({
  icon,
  text,
  description,
  banner,
  readMore,
  href,
  bg,
  className,
  style,
}: IconTextProps) => {
  const [expanded, setExpanded] = useState(false)
  const containerRef = useRef<HTMLElement>(null)
  const { mediaItemUrl: src, altText: alt } = icon
  const hasValidHref = href && href.length > 0
  const classes = classNames(styles.container, className, {
    [styles.white]: bg === "white",
  })
  const children = (
    <>
      <Image className={styles.icon} src={src} alt={alt} width="48" height="48" />
      <h4 className={styles.label}>{text}</h4>

      {/* Optional Description */}
      {description && <Wysiwyg className={styles.description} content={description} />}

      {/* Read More Description */}
      {readMore && (
        <>
          <div className={classNames(styles.readMoreWrapper, { [styles.expanded]: expanded })}>
            <Wysiwyg className={styles.readMore} content={readMore} />
          </div>
          <div className={styles.spacer}></div>
          <button className={styles.readMoreBtn} onClick={() => setExpanded(prev => !prev)}>
            Läs mer
            <CirclePlusIcon className={styles.readMoreMoreBtnIcon} open={expanded} />
          </button>
        </>
      )}

      {/* Optional Badge floating on the upper right of the card */}
      {banner && <Wysiwyg className={styles.banner} content={banner} />}
    </>
  )
  const container = createElement(
    hasValidHref ? "a" : "div",
    { ref: containerRef, className: classes, href, style },
    children
  )

  return hasValidHref ? (
    <Link href={href} legacyBehavior>
      {container}
    </Link>
  ) : (
    container
  )
}
