import { nanoid } from "nanoid"
import { ReactNode, useMemo, useRef } from "react"
import Slider, { Settings } from "react-slick"

import { SliderController } from "@components/slider-controller"
import { useResponsiveMD } from "@hooks/shared"
import { useSliderController } from "@hooks/slider"

import { Testimonial, TestimonialProps } from "./Testimonial"
import styles from "./Testimonials.module.scss"

export interface TestimonialsProps {
  testimonials: TestimonialProps[]
}

const settings: Settings = {
  arrows: false,
  dots: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  lazyLoad: "progressive",
  speed: 500,
}

export const Testimonials = ({ testimonials }: TestimonialsProps) => {
  const isResponsiveMD = useResponsiveMD()
  const sliderRef = useRef<Slider>()
  const { activeSlideIndex, slideChangeCallback, previousCallback, nextCallback, gotoCallback } =
    useSliderController(sliderRef)

  // convert testimonials to cards
  const elTestimonials = testimonials.map(t => <Testimonial key={nanoid()} {...t} />)

  // desktop - create pairs of testimonials (show 2 slides at a time)
  // mobile - only one testimonial per slide
  const slides = useMemo(
    () =>
      !isResponsiveMD
        ? elTestimonials
        : elTestimonials
            .reduce((a, v, i) => {
              const bucket = Math.floor(i / 2)
              a[bucket] = [...(a[bucket] ?? []), v]
              return a
            }, [] as ReactNode[][])
            .map(pair => (
              <div key={nanoid()}>
                <div className={styles.pair}>
                  {pair.length !== 2 ? [...pair, <div key="empty"></div>] : pair}
                </div>
              </div>
            )),
    [isResponsiveMD, elTestimonials]
  )

  return (
    <>
      <Slider
        {...settings}
        ref={slider => (sliderRef.current = slider!)}
        beforeChange={slideChangeCallback}
      >
        {slides}
      </Slider>
      <SliderController
        className={styles.controller}
        count={slides.length}
        activeIndex={activeSlideIndex}
        onPrevious={previousCallback}
        onNext={nextCallback}
        onGoTo={gotoCallback}
      />
    </>
  )
}
