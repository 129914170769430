import classNames from "classnames"
import Image from "next/legacy/image"
import { CSSProperties, MouseEventHandler, ReactNode } from "react"

import iconNext from "@/public/images/slick-next.svg"
import iconPrev from "@/public/images/slick-prev.svg"

import styles from "./AppButton.module.scss"

export interface AppButtonProps {
  className?: string
  onClick?: MouseEventHandler<HTMLButtonElement>
  disabled?: boolean
  style?: CSSProperties
  children?: ReactNode
  nav?: "prev" | "next"
}

export const AppButton = ({
  className,
  onClick,
  disabled,
  style,
  children,
  nav,
}: AppButtonProps) => {
  return (
    <button
      className={classNames(styles.arrow, className)}
      onClick={onClick}
      disabled={disabled}
      style={style}
    >
      {children}
      {nav === "prev" && <Image src={iconPrev} alt="previous" />}
      {nav === "next" && <Image src={iconNext} alt="next" />}
    </button>
  )
}
