import classNames from "classnames"
import { CSSProperties, SyntheticEvent } from "react"

import { Wysiwyg } from "@components/wysiwyg"

import styles from "./ProcessStep.module.scss"

export interface ProcessStepProps {
  order: number
  title: string
  description: string
  active?: boolean
  onClick?: (e: SyntheticEvent) => void
  onMouseEnter?: (e: SyntheticEvent) => void
  onMouseLeave?: (e: SyntheticEvent) => void
  className?: string
  style?: CSSProperties
}

export const ProcessStep = ({
  order,
  title,
  description,
  active,
  onClick,
  onMouseEnter,
  onMouseLeave,
  className,
  style,
}: ProcessStepProps) => {
  return (
    <div
      className={classNames(styles.container, className, { [styles.active]: !!active })}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={style}
    >
      <div className={styles.badge}>{order}</div>
      <div className={styles.wrapper}>
        <h4 className={styles.title}>{title}</h4>
        <Wysiwyg content={description} />
      </div>
    </div>
  )
}
