import classNames from "classnames"
import Image from "next/legacy/image"
import { CSSProperties, useEffect, useState } from "react"
import { useInView } from "react-intersection-observer"

import { WPMedia } from "@/models"

import { ProcessStep } from "./ProcessStep"
import styles from "./ProcessSteps.module.scss"

export interface ProcessStepsProps {
  steps: {
    image: WPMedia
    title: string
    description: string
  }[]
  className?: string
  style?: CSSProperties
}

export const ProcessSteps = ({ steps, className, style }: ProcessStepsProps) => {
  const { ref, inView } = useInView()
  const [isHover, setIsHover] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)
  const [hoverIndex, setHoverIndex] = useState(0)
  const elSteps = steps.map((step, index) => (
    <ProcessStep
      key={step.title}
      {...step}
      order={index + 1}
      onClick={() => setActiveIndex(index)}
      onMouseEnter={() => {
        setHoverIndex(index)
        setIsHover(hoverState => !hoverState)
      }}
      onMouseLeave={() => setIsHover(hoverState => !hoverState)}
      active={activeIndex === index}
    />
  ))

  // automatically activate other steps after x seconds
  useEffect(() => {
    if (inView) {
      // parse auto-slide delay (default to 2 seconds)
      let delay = parseInt(process.env.NEXT_PUBLIC_AUTO_SWITCH_DELAY!)
      if (!Number.isFinite(delay) || delay < 1) delay = 2

      const timer = setInterval(() => {
        const nextActiveIndex = (activeIndex + 1) % steps.length
        setActiveIndex(nextActiveIndex)
      }, delay * 1000)
      return () => clearInterval(timer)
    }
  }, [inView, activeIndex, setActiveIndex, steps])

  const imageIndex = isHover ? hoverIndex : activeIndex

  return (
    <div ref={ref} className={classNames(styles.container, className)} style={style}>
      <div className={styles.imgWrapper}>
        <Image
          key={steps[imageIndex].image.mediaItemUrl}
          src={steps[imageIndex].image.mediaItemUrl}
          alt={steps[imageIndex].image.altText}
          width={steps[imageIndex].image.mediaDetails?.width}
          height={steps[imageIndex].image.mediaDetails?.height}
        />
      </div>
      <div className={styles.stepsWrapper}>{elSteps}</div>
    </div>
  )
}
