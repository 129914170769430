import classNames from "classnames"
import Image from "next/legacy/image"

import { WPMedia } from "@/models"
import quote from "@/public/images/icon-quote.svg"
import { Wysiwyg } from "@components/wysiwyg"

import styles from "./Testimonial.module.scss"

export interface TestimonialProps {
  image: WPMedia
  content: string
  info: string
  className?: string
}

export const Testimonial = ({ image, content, info, className }: TestimonialProps) => {
  const { mediaItemUrl: src, altText: alt } = image
  const classes = classNames(styles.container, className)

  return (
    <div className={classes}>
      <div className={styles.quote}>
        <Image src={quote} alt="quote" />
      </div>

      <div className={styles.avatarWrapper}>
        <Image src={src} alt={alt} width="80" height="80" />
      </div>
      <Wysiwyg className={styles.content} content={content} />
      <span className={styles.info}>{info}</span>
    </div>
  )
}
