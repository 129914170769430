import classNames from "classnames"
import { nanoid } from "nanoid"
import { CSSProperties, useEffect, useRef, useState } from "react"

import { IconText, IconTextProps } from "./IconText"
import styles from "./IconTextGroup.module.scss"

export interface IconTextGroupProps {
  items: IconTextProps[]
  bg?: "default" | "white"
  className?: string
  style?: CSSProperties
}

export const IconTextGroup = ({ items, bg, className, style }: IconTextGroupProps) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [cardWidth, setCardWidth] = useState(0)

  useEffect(() => {
    let resizeHandler: (e: Event) => void = null!
    if (typeof window !== "undefined") {
      resizeHandler = () => {
        const containerWidth = containerRef.current?.clientWidth
        if (typeof containerWidth === "undefined") return
        setCardWidth((containerWidth - 32 * 2) / 3)
      }

      window.addEventListener("resize", resizeHandler)
      resizeHandler(null!)
    }

    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("resize", resizeHandler)
      }
    }
  }, [containerRef])

  const classes = classNames(styles.container, className)
  const elItems = items.map(item => (
    <IconText key={nanoid()} {...item} bg={bg} style={{ width: cardWidth }} />
  ))

  return (
    <div ref={containerRef} className={classes} style={style}>
      {elItems}
    </div>
  )
}
