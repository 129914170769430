import type { GetStaticProps } from "next"

import { gql } from "@urql/core"
import produce from "immer"
import invariant from "tiny-invariant"

import {
    CORE_PAGE_FIELDS, GET_GLOBAL_FIELDS, WP_BOSTAD_FIELDS, WP_MEDIA_FIELDS
} from "@/graphql-queries"
import { GQLGlobalFields, GQLHomeFields } from "@/models"
import client from "@/urql-client"
import { parseKommuns } from "@/utils"
import { AppLinks } from "@components/app-link"
import { BostadCard } from "@components/card-content-types"
import { CardGroup } from "@components/cards"
import { RegistrationFormSection } from "@components/fab-register"
import { Hero } from "@components/hero"
import { HomeNyheter } from "@components/home-nyheter"
import { IconTextGroup } from "@components/icon-text"
import { InfoGraphicCardList } from "@components/info-graphic-card"
import { Layout } from "@components/layout"
import { ProcessSteps } from "@components/process-steps"
import { Section } from "@components/section"
import { Testimonials } from "@components/testimonial"
import { TextMedia } from "@components/text-media"

import styles from "./index.module.scss"

const GET_HOME_FIELDS = gql`
  ${WP_MEDIA_FIELDS}
  ${CORE_PAGE_FIELDS}
  ${WP_BOSTAD_FIELDS}
  query GetHomeFields($id: ID!) {
    page(id: $id, idType: DATABASE_ID) {
      ...CorePageFields
      acfHome {
        intro {
          sectionId
          sectionTitle
          title
          description
          image {
            ...WPMediaFields
          }
          iconList {
            icon {
              ...WPMediaFields
            }
            iconText
            bannerText
          }
          link {
            label
            variant
            icon
            href
          }
        }
        process {
          sectionTitle
          title
          steps {
            image {
              ...WPMediaFields
            }
            title
            description
          }
        }
        bostad {
          sectionTitle
          title
          list {
            bostadItem {
              ...WPBostadFields
            }
          }
          link {
            label
            variant
            icon
            href
          }
        }
        news {
          sectionTitle
          sectionLink {
            label
            variant
            icon
            href
          }
        }
        testimonial {
          sectionTitle
          testimonials {
            image {
              ...WPMediaFields
            }
            content
            info
          }
        }
        infographic {
          sectionTitle
          cards {
            icon {
              ...WPMediaFields
            }
            title
            pageLink
          }
        }
      }
    }
  }
`

export const getStaticProps: GetStaticProps<IndexProps> = async () => {
  // ensure necessary env vars exist
  invariant(process.env.WP_PAGEID_HOME, "WP_PAGEID_HOME is undefined")

  // fetch global acf and page-specific data concurrently
  const [{ data: rawGlobalFields }, { data: home }] = await Promise.all([
    client.query<GQLGlobalFields>(GET_GLOBAL_FIELDS, {}).toPromise(),
    client.query<GQLHomeFields>(GET_HOME_FIELDS, { id: process.env.WP_PAGEID_HOME }).toPromise(),
  ])

  // ensure necessary data exist
  invariant(rawGlobalFields, "rawGlobalFields is undefined")
  invariant(home, "home is undefined")

  // process some acf fields (e.g., kommuns csv)
  const globalFields = produce(rawGlobalFields, draft => {
    draft.acfGlobalFields.acfGlobal.newsletter.kommunItems = parseKommuns(
      rawGlobalFields.acfGlobalFields.acfGlobal.newsletter.kommunItems as string
    )
  })

  return {
    props: { globalFields, home },
  }
}

interface IndexProps {
  globalFields: GQLGlobalFields
  home: GQLHomeFields
}

export default function Index({ globalFields, home }: IndexProps) {
  const { seo, featuredImage, acfHero, acfHome } = home.page
  const { intro, process, bostad, news, testimonial, infographic } = acfHome
  const iconTextItems = intro.iconList.map(({ icon, iconText, bannerText }) => ({
    icon,
    text: iconText,
    banner: bannerText,
  }))
  const bostadList = bostad.list.map(
    ({ bostadItem: { id, slug, title, featuredImage, acfBostad } }) => ({
      id,
      image: featuredImage?.node,
      title,
      location: acfBostad.info.location,
      status: acfBostad.info.status,
      details: acfBostad.info.details,
      link: `/hitta-bostad/${slug}`,
      externalLink: acfBostad.info.referenceLink,
      description: acfBostad.info.description,
    })
  )

  return (
    <Layout seo={seo} globalFields={globalFields}>
      <Hero featuredImage={featuredImage.node} hero={acfHero} links={acfHero.links} fullHeight />

      <Section id={intro.sectionId} label={intro.sectionTitle} variant="light">
        <TextMedia
          title={intro.title}
          contents={intro.description}
          image={intro.image}
          className={styles.columnReverse}
        />
        <IconTextGroup className={styles.introIconTextGroup} items={iconTextItems} />
        <AppLinks className={styles.introLinks} link={intro.link} />
      </Section>

      <Section label={process.sectionTitle} title={process.title} variant="lighter">
        <ProcessSteps steps={process.steps} />
      </Section>

      <Section label={bostad.sectionTitle} title={bostad.title} variant="light">
        <CardGroup className={styles.bostadCards} data={bostadList} component={BostadCard} />
        <AppLinks className={styles.bostadLinks} link={bostad.link} />
      </Section>

      <Section title={news.sectionTitle} link={news.sectionLink} variant="lighter">
        <HomeNyheter className={styles.nyheterCards} />
      </Section>

      <Section label={testimonial.sectionTitle}>
        <Testimonials testimonials={testimonial.testimonials} />
      </Section>

      <Section label={infographic.sectionTitle} variant="light">
        <InfoGraphicCardList cards={infographic.cards} />
      </Section>

      <RegistrationFormSection />
    </Layout>
  )
}
