import classNames from "classnames"
import { CSSProperties, useMemo, useRef } from "react"
import Slider, { Settings } from "react-slick"

import { ApiResultsWrapper } from "@components/api-results-wrapper"
import { NyheterCard } from "@components/card-content-types"
import { CardGroup } from "@components/cards"
import { SliderController } from "@components/slider-controller"
import { useHomeNyheter } from "@hooks/nyheter"
import { useResponsiveMD } from "@hooks/shared"
import { useSliderController } from "@hooks/slider"

import styles from "./HomeNyheter.module.scss"

export interface HomeNyheterProps {
  className?: string
  style?: CSSProperties
}

const settings: Settings = {
  arrows: false,
  dots: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  lazyLoad: "progressive",
  speed: 500,
}

export const HomeNyheter = ({ className, style }: HomeNyheterProps) => {
  const isResponsiveMD = useResponsiveMD()
  const sliderRef = useRef<Slider>()
  const { activeSlideIndex, slideChangeCallback, previousCallback, nextCallback, gotoCallback } =
    useSliderController(sliderRef)
  const { data, isLoading, error } = useHomeNyheter()
  const nyheterList = useMemo(
    () =>
      data?.nyheters.edges.map(({ node: { id, slug, title, date, featuredImage, excerpt } }) => ({
        id,
        image: featuredImage?.node,
        title,
        date,
        excerpt,
        link: `/nyheter/${slug}`,
      })),
    [data]
  )

  return (
    <>
      {/* Desktop Layout */}
      {isResponsiveMD && (
        <ApiResultsWrapper isLoading={isLoading} error={error}>
          <CardGroup
            className={classNames(styles.nyheterCards, className)}
            data={nyheterList}
            component={NyheterCard}
            getKey={x => x.title}
            style={style}
          />
        </ApiResultsWrapper>
      )}

      {/* Mobile Layout */}
      {!isResponsiveMD && (
        <div className={classNames(styles.nyheterSliderWrapper, className)} style={style}>
          <ApiResultsWrapper isLoading={isLoading} error={error}>
            <Slider
              {...settings}
              ref={slider => (sliderRef.current = slider!)}
              beforeChange={slideChangeCallback}
            >
              {nyheterList?.map(nyheter => (
                <NyheterCard key={nyheter.title} {...nyheter} />
              ))}
            </Slider>
            <SliderController
              className={styles.silderController}
              count={nyheterList?.length ?? 0}
              activeIndex={activeSlideIndex}
              onPrevious={previousCallback}
              onNext={nextCallback}
              onGoTo={gotoCallback}
            />
          </ApiResultsWrapper>
        </div>
      )}
    </>
  )
}
