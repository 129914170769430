import classNames from "classnames"
import Image from "next/legacy/image"
import { CSSProperties, ReactNode } from "react"

import { WPMedia } from "@/models"
import { Wysiwyg } from "@components/wysiwyg"
import { useResponsiveLG } from "@hooks/shared"

import styles from "./TextMedia.module.scss"

export interface TextMediaProps {
  title: string
  contents: string
  image?: WPMedia
  moreInformation?: {
    title: string
    imageDesktop: WPMedia
    imageMobile: WPMedia
    description: string
  }
  className?: string
  style?: CSSProperties
  children?: ReactNode
}

export const TextMedia = ({
  title,
  contents,
  image,
  moreInformation,
  className,
  style,
  children,
}: TextMediaProps) => {
  const isResponsiveLG = useResponsiveLG()
  const moreInformationImage = moreInformation
    ? isResponsiveLG
      ? moreInformation.imageDesktop
      : moreInformation.imageMobile
    : null

  return (
    <div className={classNames(styles.container, className)} style={style}>
      <div className={styles.wrapper}>
        <h3 className="app-subheading">{title}</h3>
        <Wysiwyg className={styles.contents} content={contents} />
      </div>
      <div className={styles.rightWrapper}>
        {image && (
          <div className={styles.imageWrapper}>
            <Image src={image.mediaItemUrl} alt={image.altText} layout="fill" objectFit="contain" />
          </div>
        )}
        {moreInformation && (
          <div className={styles.moreInformation}>
            <h3
              className={styles.title}
              dangerouslySetInnerHTML={{ __html: moreInformation.title }}
            ></h3>
            <Image
              src={moreInformationImage?.mediaItemUrl!}
              alt={moreInformationImage?.altText}
              width={moreInformationImage?.mediaDetails?.width}
              height={moreInformationImage?.mediaDetails?.height}
            />
            <Wysiwyg className={styles.description} content={moreInformation.description} />
          </div>
        )}
        {children}
      </div>
    </div>
  )
}
