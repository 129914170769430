import { ReactNode } from "react"

import styles from "./ApiResultsWrapper.module.scss"

export interface ApiResultsWrapper {
  isLoading: boolean
  error: any
  children: ReactNode
}

export const ApiResultsWrapper = ({ isLoading, error, children }: ApiResultsWrapper) => {
  if (isLoading) {
    return (
      <div className={styles.container}>
        <div className={styles.loading}></div>
      </div>
    )
  }

  if (error) {
    return <div className={styles.container}>😭 Oops! An error occurred.</div>
  }

  return <>{children}</>
}
