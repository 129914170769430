import classNames from "classnames"
import { CSSProperties } from "react"

import { BostadStatus, WPMedia } from "@/models"
import { AppLink } from "@components/app-link"
import { Card, CardCommonProps } from "@components/cards"
import { Wysiwyg } from "@components/wysiwyg"

import styles from "./BostadCard.module.scss"

export interface BostadCardProps extends CardCommonProps {
  image: WPMedia
  title: string
  location: string
  status: BostadStatus
  description?: string
  details?: { label: string; value: string }[]
  link: string
  externalLink: string
  className?: string
  style?: CSSProperties
}

export const BostadCard = ({
  image,
  title,
  location,
  status,
  description,
  details,
  link,
  externalLink,
  className,
  style,
}: BostadCardProps) => {
  const svStatus =
    status === "ongoing"
      ? "Pågående"
      : status === "completed"
      ? "Avslutade"
      : status === "coming"
      ? "Kommande"
      : status === "inflyttningsklar"
      ? "Inflyttningsklar"
      : null
  const elDetails =
    status !== "coming" &&
    details?.map(({ label, value }) => (
      <div key={label + value} className={styles.item}>
        <span>{label}</span>
        <span>{value}</span>
      </div>
    ))

  return (
    <Card
      image={image}
      className={classNames(className, styles.card)}
      style={style}
      blur={status === "coming"}
      shadow
      imageLink={status !== "coming" ? link : undefined}
    >
      <div className={styles.wrapper}>
        <h4 className={styles.title}>{title}</h4>
        <div className={styles.meta}>
          <span className={styles.location}>{location}</span>
          <span className={styles.status}>{svStatus}</span>
        </div>
        {description && <Wysiwyg className={styles.description} content={description} />}
        <div className={styles.details}>{elDetails}</div>
        {status !== "coming" && (
          <div className={classNames("bostad-actions", styles.actions)} data-bostad={title}>
            <AppLink className="bostad-read-more" href={link} label="Läs mer" />
            <AppLink
              className="bostad-broker"
              href={externalLink}
              label="Till mäklaren"
              variant="green"
            />
          </div>
        )}
      </div>
    </Card>
  )
}
