import classNames from "classnames"
import { nanoid } from "nanoid"
import { CSSProperties, Fragment, Key } from "react"

import styles from "./CardGroup.module.scss"

export interface CardCommonProps {
  id?: Key
}

export interface CardGroupProps<T extends CardCommonProps> {
  data?: T[]
  getKey?: (item: T) => Key
  component: (props: T) => JSX.Element
  className?: string
  style?: CSSProperties
  singleRow?: boolean
}

export const CardGroup = <T extends CardCommonProps>({
  data,
  getKey,
  component,
  className,
  style,
  singleRow,
}: CardGroupProps<T>) => {
  // skip rendering of card group if no data was given
  if (!Array.isArray(data) || data.length < 1) return null

  // build the individual cards of this card group
  const cards = data.map(x => {
    const key = getKey?.(x) ?? x.id ?? nanoid()
    return <Fragment key={key}>{component(x)}</Fragment>
  })

  return (
    <div
      className={classNames(styles.cardsList, className, { [styles.singleRow]: singleRow })}
      style={style}
    >
      {cards}
    </div>
  )
}
