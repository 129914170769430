import { format, parseISO } from "date-fns"
import { CSSProperties } from "react"

import { WPMedia } from "@/models"
import { Card, CardCommonProps } from "@components/cards"
import { Wysiwyg } from "@components/wysiwyg"

import styles from "./NyheterCard.module.scss"
import classNames from "classnames"

export interface NyheterCardProps extends CardCommonProps {
  image: WPMedia
  title: string
  date: string
  excerpt: string
  link: string
  className?: string
  style?: CSSProperties
}

export const NyheterCard = ({
  image,
  title,
  date,
  excerpt,
  link,
  className,
  style,
}: NyheterCardProps) => {
  return (
    <Card
      image={image}
      href={link}
      className={classNames(styles.card, className)}
      style={style}
      shadow
    >
      <div className={styles.wrapper}>
        <h4 className={styles.title}>{title}</h4>
        <h5 className={styles.date}>{format(parseISO(date), "dd MMM yyyy")}</h5>
        <Wysiwyg className={styles.content} content={excerpt} />
      </div>
    </Card>
  )
}
