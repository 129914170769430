import classNames from "classnames"
import { CSSProperties } from "react"

import { WPMedia } from "@/models"
import { Card, CardCommonProps } from "@components/cards"

import styles from "./BostadFeature.module.scss"

export interface BostadFeatureProps extends CardCommonProps {
  image: WPMedia
  label: string
  details: { label: string; value: string }[]
  className?: string
  style?: CSSProperties
}

export const BostadFeature = ({ image, label, details, className, style }: BostadFeatureProps) => {
  const elDetails = details.map(({ label, value }) => (
    <div key={label + value} className={styles.item}>
      <span>{label}</span>
      <span>{value}</span>
    </div>
  ))

  return (
    <Card image={image} className={classNames(className, styles.card)} style={style}>
      <div className={styles.wrapper}>
        <h4 className={styles.label}>{label}</h4>
        <div>{elDetails}</div>
      </div>
    </Card>
  )
}
