import useSWR from "swr"

import { GQLNyheterList } from "@/models"

export function useHomeNyheter() {
  const { data, error } = useSWR(["/api/nyheter", 1, 3], fetcher)
  return { data, isLoading: !data, error }
}

export function useNyheterFilters(page: number) {
  const { data, error } = useSWR(["/api/nyheter", page], fetcher)
  return { data, isLoading: !data, error }
}

async function fetcher([api, page, size]: [
  string,
  number,
  number | undefined
]): Promise<GQLNyheterList> {
  // optionally, add size query parameter if it's given
  let url = `${api}?page=${page}`
  if (Number.isSafeInteger(size)) url += `&size=${size}`

  const response = await fetch(url)
  const nyheters: GQLNyheterList = await response.json()

  return nyheters
}
