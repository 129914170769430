import { CSSProperties } from "react"

import { WPMedia } from "@/models"
import { AppLink } from "@components/app-link"
import { Card, CardCommonProps } from "@components/cards"

import styles from "./SocialObligation.module.scss"

export interface SocialObligationProps extends CardCommonProps {
  image: WPMedia
  title: string
  link: string
  className?: string
  style?: CSSProperties
}

export const SocialObligation = ({
  image,
  title,
  link,
  className,
  style,
}: SocialObligationProps) => {
  return (
    <Card image={image} className={className} imageHeight={178} style={style} shadow>
      <div className={styles.wrapper}>
        <h4 className={styles.title}>{title}</h4>
        <AppLink
          className={styles.link}
          label="Ladda ned"
          href={link}
          variant="green"
          icon="download"
        />
      </div>
    </Card>
  )
}
