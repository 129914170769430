import { InfoGraphicCard, InfoGraphicCardProps } from "./InfoGraphicCard"
import styles from "./InfoGraphicCardList.module.scss"

interface ImageProps {
  cards: InfoGraphicCardProps[]
}

export const InfoGraphicCardList = ({ cards }: ImageProps) => {
  const elCards = cards.map((card) => {
    return <InfoGraphicCard key={card.title} {...card} />
  })

  return <div className={styles.cardsContainer}>{elCards}</div>
}
