import Image from "next/legacy/image"
import Link from "next/link"

import { WPMedia } from "@/models"

import styles from "./InfoGraphicCard.module.scss"

export interface InfoGraphicCardProps {
  icon: WPMedia
  title: string
  pageLink: string
}

export const InfoGraphicCard = ({ icon, title, pageLink }: InfoGraphicCardProps) => {
  const { mediaItemUrl, altText, mediaDetails } = icon
  const isAppLink = typeof pageLink === "string" && pageLink.startsWith("/")
  const container = (
    <a className={styles.card} href={pageLink}>
      <div className={styles.cardImgWrapper}>
        <Image
          src={mediaItemUrl}
          alt={altText}
          width={mediaDetails?.width}
          height={mediaDetails?.height}
        />
      </div>
      <div className={styles.titleWrapper}>
        <h3 className={styles.title}>{title}</h3>
      </div>
    </a>
  )

  return isAppLink ? (
    <Link href={pageLink} legacyBehavior>
      {container}
    </Link>
  ) : (
    container
  )
}
